.contianer{
  width: 40%;
  margin: 100px auto 0px;
}
.showBorad{
  word-break: break-all;
  padding: 5%;
}
.mark{
  background: pink;
}
.table {
  padding: 5%;
}

.ant-table{

    color: royalblue !important;
    background: red !important;

}