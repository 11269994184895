.page{
  height: 100vh;
  padding: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.title{
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.content{
  width: 300px;
  margin: 0 auto;
}
.contactMe{
  font-size: large;
}
.line{
  display: flex;
  margin: 10px 0;
}
.lineTitle{

  width: 50px;
}
.memo{
  margin: 20px 0 10px;
}
.icon {

  border-radius: 50%;
  margin: auto;
  width: 400px;

  margin-left: calc(50% - 200px);
}