* {
  margin: 0;
  padding: 0;
}
#root {
  font-family: Helvetica Neue, Helvetica, Arial, Microsoft Yahei, Hiragino Sans GB, Heiti SC, WenQuanYi Micro Hei, sans-serif;
  position: relative;
  color: #222;
  font-size: 14px;
  background-color: #d2e9fb;
  height: 200%;
}