.app{
    /* background: #d2e9fb url(https://s2.hdslb.com/bfs/static/blive/blfe-dynamic-web/static/img/background.bc725153.png) top/contain no-repeat;
    min-height: 55vw; */
}
.fix_bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url(//s2.hdslb.com/bfs/static/blive/blfe-dynamic-web/static/img/background.bc725153.png);
    background-size: cover;
    background-position: center;
}
.Singlelineellipsis {
    overflow: hidden;
    text-overflow:ellipsis; 
    white-space: nowrap;
}

.ant-table{

    color: #bdbdbd !important;
    background: #262626 !important;

}
.ant-radio-wrapper{
    color: #bdbdbd;
}
.ant-table-row:hover{
    /* color: #000; */
    background: #7e7777;
}

.ant-table-row:hover .ant-radio-wrapper{
    /* color: #000 !important; */
    
}

table:hover {
    background: none !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}
.ant-spin-blur:after{
    opacity: 0 !important;

}
.ant-spin-blur{

    opacity: 0.9 !important;
}